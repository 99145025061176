.spinner {
    width: 64px;  /* Equivalent to w-16 in Tailwind (16 * 4px = 64px) */
    height: 64px; /* Equivalent to h-16 */
    margin: 32px; /* Equivalent to m-8 */
    border-radius: 50%; /* Equivalent to rounded-full */
    background-color: #0ea5e9; /* Equivalent to bg-sky-600 */
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite; /* Equivalent to animate-ping */
  }
  
  /* Define the ping animation */
  @keyframes ping {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    75%, 100% {
      transform: scale(2);
      opacity: 0;
    }
  }
  