/*
=============== 
Navbar
===============
*/

/* Navbar links styling */
.links a {
  color: var(--clr-primary-10);
  font-size: 1rem;
  text-transform: lowercase;
  letter-spacing: var(--spacing);
  display: block;
  padding: 0.5rem 1.5rem;
  transition: var(--transition);
}
.links a:hover {
  background: var(--clr-primary-8);
  color: var(--clr-primary-5);
  padding-left: 1.5rem;
}
.links {
  height: 0;
  overflow: hidden;
  transition: var(--transition);
}

.login, .logout {
  display: none;
  overflow: hidden;
  transition: var(--transition);
}

.logout:hover, .login:hover {
  color: var(--clr-primary-5);
}


nav {
  position: fixed;
  top: 0; 
  left: 0; 
  width: 100%; 
  background: var(--clr-black);
  box-shadow: var(--light-shadow);
  color: white; 
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  transition: transform 0.3s ease; /* Smooth transition effect */
  z-index: 1000; /* Ensure it stays above other content */
}

.nav-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0.8rem 1rem 0.8rem 1rem;
}

.nav-header {
  display: flex;
  align-items: center;
}

.nav-header img {
  margin-top: 10px
}

.nav-toggle {
  font-size: 1.5rem;
  color: var(--clr-primary-10);
  background: transparent;
  border-color: transparent;
  transition: var(--transition);
  cursor: pointer;
}
.nav-toggle:hover {
  color: var(--clr-primary-10);
  transform: rotate(90deg);
}

.logo {
  height: 50px;
}

.nav-title {
  margin-top: 10px;
}

.nav-title h4 {
  color: var(--clr-primary-10);
  font-size: 0.7rem;
  letter-spacing: var(--spacing);
  transition: var(--transition);
  line-height: 0.2;
}

@media (min-width: 800px) {
  .nav-toggle {
    display: none;
  }
  .links {
    height: auto;
    display: flex;
  }
  .login {
    height: auto;
    display: flex;
    color: azure;
    cursor: pointer;
  }
  .logout {
    color: azure;
    display: flex;
    border: 0;
    background-color: var(--clr-black);
    cursor: pointer;
  }
  .links a {
    padding: 0;
    margin: 0 0.5rem;
  }
  .links a:hover {
    padding: 0;
    background: transparent;
  }
  .logo {
    height: 90px;
  }
  .nav-title h4 {
    color: var(--clr-primary-10);
    font-size: 1rem;
    letter-spacing: var(--spacing);
    transition: var(--transition);
    line-height: 0.6;
  }
}


/*
=============== 
Footer
===============
*/
footer {
  background-color: #222;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.footer-links {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
}

.footer-links li {
  margin: 0 15px;
}

.footer-links a {
  color: white;
  text-transform: lowercase;
  text-decoration: none;
}

.copyright {
  margin-top: 20px;
  font-size: 14px;
}

/* Narrow screen Drop down menu */
.dropdown {
  position: absolute;
  left: 0;
  top: 150;
  margin-top: 250px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #222;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  list-style: none;
}

.dropdown li {
  padding: 8px 24px;

}

.dropdown li a, .dropdown li button {
  text-decoration: none;
  color: white;
  display: block;
  background-color: #222;
  border: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  cursor: pointer;
}

.dropdown li a:hover, .dropdown li button:hover {
  color: var(--clr-primary-5);
}


.nav-center.hidden {
  transform: translateY(-100%); /* Move the nav center up out of view */
}

.nav-center.visible {
  transform: translateY(0); /* Move the nav center back into view */
}