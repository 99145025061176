
.life-paragraph li {
  font-family: "Open Sans", sans-serif;
  background: var(#6D85EE);
  color: var(#6D85EE);
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: justify;
  margin-bottom: 15px;
}

.full-width-image img {
  width: 100%;
  height: 350px; /* Set a specific height */
  object-fit: cover; 
}

.life-title-text {
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 20px;
  color: var(--clr-grey-5);
}

.text-container {
  padding: 30px 80px;
  text-align: left;
  margin-bottom: 20px;
}

.inline-image {
  float: right;
  width: 40%;
  margin: 30px 0px 20px 30px;
}

.inline-image-two {
  float: left;
  width: 40%;
  margin: 10px;
}

.inline-image img, .inline-image-two img {
  width: 100%;
  height: auto;
}

.life-bottom-text {
  margin-bottom: 160px
}

.map-all-container {
  display: flex;
  flex-direction: row;
}

.map-margin {
  width: 100px
}

.map-container {
  width: 90%;
}

@media (max-width: 600px) {
  .inline-image, .inline-image-two {
    float: none;
    width: 100%;
    margin: 10px 0;
    border-radius: 0;
  }
}

.city-buttons {
  margin-top: 20px;
  text-align: center;
}

.city-buttons button {
  margin: 5px;
  padding: 10px;
  cursor: pointer;
}

.city-info {
  margin-top: 20px;
  text-align: center;
}

.marker-label {
  background-color: white;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 12px;
  border: 1px solid #333;
  transform: translate(-50%, -100%); /* Ensures the label appears above the marker */
}

.map-and-key-container {
  display: flex;
  width:95%;
  flex-direction: row; 
  text-align: center;
  padding: 0 0 0 60px;
}

.life-section {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9; /* Light background for contrast */
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.life-title-text {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
}

.life-paragraph {
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: justify;
  margin-bottom: 20px;
}

.map-life-paragraph {
  font-size: 1rem;
  line-height: 1.6;
  text-align: justify;
  margin-bottom: 20px;
}

@media (max-width: 850px) { /* Adjust the breakpoint as needed */
  .hide-on-small {
      display: none; /* Hides the text */
  }
}


@media (max-width: 800px) {
  .life-section {
    padding: 15px;
  }

  .life-title-text {
    font-size: 1.8rem;
  }

  .life-paragraph {
    font-size: 1rem;
  }

  .map-and-key-container {
    display: flex;
    width:100%;
    flex-direction: row; 
    text-align: center;
    padding-left: 12px;
  }
  .text-container {
    text-align: left;
    margin-bottom: 20px;
    padding: 0px
  }
  .text-container p {
    margin-left: 20px
  }
}
.city-info {
  max-width: 200px; /* Set a maximum width for better readability */
  margin: 20px auto; /* Center the component with auto margins */
  padding: 20px; /* Add padding for spacing */
  color: #555;
}

.city-info h3 {
  font-size: 1.5rem; /* Set font size for the heading */
  margin-bottom: 10px; /* Space below the heading */
  text-align: left; /* Left-align the heading */
}

.city-info p {
  text-align: left; /* Left-align text */
  line-height: 1.6; /* Line height for better readability */
  margin: 10px 0; /* Margin between paragraphs */
}

.city-name {
  font-size: 20px;
}

/* Optional: Responsive styles */
@media (max-width: 600px) {
  .city-info {
      padding: 15px; /* Adjust padding for smaller screens */
  }

  .city-info h3 {
      font-size: 1.25rem; /* Smaller heading font size on small screens */
  }
}
