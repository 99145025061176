/* Link styles for the add button */

.post-container {
  display:flex;
  flex-direction: column;
  width: 100%
}

.post-title {
    padding: 30px 20px 0px 20px;
    display: flex;
    width: 90%;
    flex-direction: row;
    justify-content: space-between; 
  }

.right-side-title {
  display: flex;
  align-items: center; 
  justify-content: space-between; 
}

.right-side-title button{
  background-color: transparent;
  border-width: 0px;
  cursor: pointer;
  margin-left: 20px;
}

.add-button {
  color: #0056b3; 
  font-size: 4rem; 
}

.add-button:hover{
  color: #004494;
  font-size: 4rem; 
}

.money-button {
  color: goldenrod; 
  font-size: 3rem; 
}

.money-button:hover{
  color: darkgoldenrod;
  font-size: 3rem; 
}

  .post-title-text {
    letter-spacing: var(--spacing);
    line-height: 1.25;
    margin-bottom: 0.75rem;
    font-family: "Roboto", sans-serif;
    font-size: 3rem;
    margin-top: 5px;
    text-align: left;
  }

  .opening-button {
    background-color: #0056b3;
    color: #fff;
    border-radius: 0.5rem; 
    padding: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 1.5rem
  }

  .opening-button:hover {
    background-color: #004494;
  }

  .post-question {
  position: absolute; /* Positions the question mark relative to its closest positioned ancestor */
  top: -15px; /* Adjust this value to position it above the button */
  right: -15px; /* Adjust this value to position it to the right of the button */
  cursor: pointer; /* Changes the cursor to a pointer when hovering over the question mark */
  /* Add any additional styling here, such as color or size */
  font-size: 1.2em; /* Example to make the question mark larger */
  }

  .post-icon-container {
    position: relative;
    display: inline-block;
  }