
/* General Styles */

h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
h3 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

.title-text {
  letter-spacing: var(--spacing);
  line-height: 1.25;
  font-family: "Roboto", sans-serif;
  font-size: 3rem;
  margin: 5px;
  text-align: right;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px; 
  position: relative;
}

.submit-button {
    background-color: #0284c7;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block;
    margin: 0 auto;
}
.submit-button:hover {
  background-color: #0056b3;
}

/*                   */
/* Create Job Styles */
/*                   */

  .form-title { 
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
    text-align: center;
  }

  .form-group {
    margin-bottom: 16px;
  }
  
  .form-group label {
    display: block;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .form-group input, .form-group select, .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    color: black;
    background: white;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }

  .form-group select {
    height: 40px
  }
  
  .form-group input:focus, .form-group textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  .form-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block;
    margin: 0 auto;
  }

  .currency-input {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .description-edit {
    height: 180px;
    font-family: "Open Sans", sans-serif;
    width: 100%;
    resize: none;
  }

  .form-button:hover {
    background-color: #0056b3;
  }
  
  .spinner {
    text-align: center;
    margin-bottom: 16px;
  }

  /*            */
  /* Show Page */
  /*           */

  .action-content {
    text-align: left;
    background: white;
    width: 70%;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    margin-top: 30px
  }
  
  .detail-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 10px;
    background: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .detail-group span:first-child {
    font-weight: bold;
    margin-right: 10px
  }

  

  /* Delete Page  */

  .delete-button {
    background-color: #dc3545;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }

  .salary-container {
    display: flex;
  }

  .location-container {
    display: flex;
    margin-top: 30px;
  }


  .extra-margin-right {
    margin-right: 10px
  }
  .extra-extra-margin-right {
    margin-right: 30px
  }

  @media (max-width: 800px) {

      .title-text {
        font-size: 2rem;
    }
    .location-container {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
    }
    .action-content {
      text-align: left;
      background: white;
      width: 90%;
      padding: 2rem;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 30px;
      margin-top: 30px
    }
  }