
/* ///////// */
/* Styles for singleJob Page */
/* //////// */

.single-job-content {
    margin: 0 auto;
    width: 90%;
    display: flex;
    text-align: left;
    max-width: 800px;
    flex-direction: column;
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.intro-container {
    display: flex;
    justify-content: space-between;
}

.school-container {
    text-align: right;
}

.badge-container {
    display: flex;
    justify-content: left;
    margin-top: 15px;
    margin-bottom: 15px
}

.description-container {
    display: flex;
    justify-content: left;
    text-align: left;
}

.extra-badge {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #f1f1f1;
    color: #555;
}

@media (max-width: 800){
    .badge-container h4{
        font-size: 0.2rem
    }
    .badge-container {
        display: flex;
        justify-content: left;
        margin-top: 15px;
        margin-bottom: 15px
    }
}