/* ///////// */
/* Styles for Home Page */
/* //////// */

.jobs-board {
    position: relative;
    padding-top: 10px;
}

.job-sort {
    padding-right: 40px;
    font-size: 15px;
}

.job-container-wide {
    display: flex;
    flex-direction: row;
    padding-top: 100px;
    width: 100%;
}

.job-container-narrow {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.job-filter-container-wide {
    width: 230px;
    flex-direction: column;
    text-align: left;
    margin-left: 30px;
    margin-right: 40px;
    margin-top: 10px;
    padding: 30px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    flex: 1;
    max-height: 80vh;
    /* Adjust height as needed */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.job-filter-container-narrow {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px 20px 0px 20px
}

.job-list-container {
    width: 70%;
    text-align: right
}


.jobs-board {
    margin: 10px;
    padding: pointer;
    flex: 1;
    max-height: 80vh;
    /* Adjust height as needed */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

/* Job Item */
.job-item {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 50px;
    margin-left: 30px;
    margin-right: 30px;
    padding: 30px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    z-index: 999;
}

.job-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    z-index: 999;
}

.job-item h3 {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    color: #333;
    transition: color 0.2s, text-decoration 0.2s;
}

.job-item h3:hover {
    color: #007bff;
    text-decoration: underline;
}

.job-item p {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    color: #666;
}

.title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -10px;
}

.description-short {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 3em;
    /* 3 lines * line-height (1.5em) */
    line-height: 1.5em;
}

/* Badge styles */
.job-badge {
    display: inline-block;
    margin-top: -10px;
    margin-bottom: 10px;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #f1f1f1;
    color: #555;
    font-size: 1rem;
    width: fit-content;
}

.job-bottom-bar {
    color: grey;
    font-size: 0.9rem;
}

.job-contact-text {
    color: #555
}

/* Time posted */
.job-posted {
    font-size: 0.9rem;
    color: #999;
}

/* Container for new badge */
.job-new-badge {
    display: inline-block;
    background-color: #e91e63;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9rem;
}


.created-date {
    font-size: 0.8rem !important;
    margin-top: 5px !important;
}


.jobs-empty-text {
    color: #d32f2f;
    /* Red for emphasis */
    background-color: #fce4ec;
    /* Light pink background for contrast */
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    /* Centered text */
    font-size: 20px;
    /* Adjust font size */
    font-weight: bold;
    /* Bold text */
    margin: 20px;
    /* Add some margin */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    /* Soft shadow */
}

/* Filter section styles */

.job-filter {
    width: 300px;
    min-width: 300px;
    font-family: Arial, sans-serif;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.filter-section {
    margin-bottom: 20px;
}

.filter-section h3 {
    margin: 0;
    padding-bottom: 10px;
    font-size: 18px;
}

.filter-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.filter-checkbox input {
    margin-right: 10px;
}

.divider {
    border-top: 1px solid #ccc;
    margin: 20px 0;
}
.divider-end {
    border-top: 1px solid white;
    margin: 50px 0;
}

.filter-dropdown label {
    display: block;
    margin-bottom: 10px;
}

.filter-dropdown select {
    width: 160px;
    padding: 8px;
    font-size: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.back-arrow {
    font-size: 40px;
}

.pagination-controls {
    margin-right: 40px;
}

.pagination-controls button {
    font-size: 20px;
    background-color: white;
    margin-right: 15px;
    margin-left: 15px;
    border-width: 0;
    border-radius: 15;
    padding: 5px;
    cursor: pointer;
}

.pagination-controls span {
    font-size: 20px;
    background-color: white;
    border-radius: 15;
    padding: 5px
}

.filter {
    transition: max-height 0.9s ease, transform 0.9s ease; /* Transition for max-height and transform */
    /* overflow: hidden; Hide overflow during collapse */
    /* max-height: 1500px; Set a max height to the content (adjust as needed) */
  }
  
  .filter.hidden {
    transform: translateY(-100%); /* Move up */
    max-height: 0; /* Collapse height to 0 */
  }
  
  .filter.visible {
    transform: translateY(0); /* Reset position */
    max-height: 500px; /* Reset to the max height */
  }



@media (max-width: 900px) {

    .job-list-container {
        width: 100%;
    }

    .job-filter-container {
        display: none;
    }

    .filter-narrow {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0px 20px 0px 20px
    }

    .filter-title {
        margin-top: 30px
    }

    .divider {
        display: none
    }

    .divider-end {
        display: none
    }

    .narrow-divider {
        width: 80%;
        border-top: 1px solid #ccc;
        ;
        margin: 0;
    }

    .filter-section {
        padding: 3px;
    }

    .filter-section h3 {
        margin: 0;
        padding-bottom: 5px;
        font-size: 18px;
    }

    .filter-dropdown select {
        width: 140px;
        height: 40px;
        padding: 8px;
        font-size: 0.8rem;
        border: 1px solid #ccc;
        color: black;
        background: white;
        border-radius: 5px;
        box-sizing: border-box;
        transition: border-color 0.3s ease, box-shadow 0.3s ease;
    }
}