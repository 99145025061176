.map-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    min-width: 250px;
  }
  
  .city-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 20px;
    min-width: 250px;
  }
  
  .prev,
  .next {
    position: absolute;
    transform: translateY(-50%);
    background: var(--clr-grey-5);
    color: var(--clr-white);
    margin-top: 10px;
    width: 1.25rem;
    height: 1.25rem;
    display: grid;
    place-items: center;
    border: none;
    font-size: 1rem;
    border-radius: var(--radius);
    cursor: pointer;
    transition: var(--transition);
    z-index: 10; /* Ensure buttons stay on top */
  }
  
  .prev:hover,
  .next:hover {
    background: var(--clr-primary-5);
  }
  
  .prev {
    left: 0;
  }
  
  .next {
    right: 0;
  }
  
  @media (min-width: 800px) {
    .prev,
    .next {
      width: 2rem;
      height: 2rem;
      font-size: 1.5rem;
    }
  }
  
  .city-slider {
    position: relative;
    width: 100%;
    height: 50px; /* Adjust height as needed */
    overflow: hidden;
  }
  
  article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: var(--transition);
  }
  
  article.activeSlide {
    opacity: 1;
    transform: translateX(0);
  }
  
  article.lastSlide {
    transform: translateX(-100%);
  }
  
  article.nextSlide {
    transform: translateX(100%);
  }
  
  .city-name-text {
    font-size:30px;
  }