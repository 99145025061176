/* Main container with a gradient background */
.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  background: linear-gradient(to bottom, #6BB4EC, white);
  min-height: 88vh;

}
.about-container-wide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  background: linear-gradient(to bottom, #6BB4EC, white);
  min-height: 88vh;
  gap: 20px;
}

.contact-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 40px;
  background: linear-gradient(to bottom, #6BB4EC, white);
  min-height: 88vh;
  gap: 20px;
}

/* Columns for text and form */
.about-column-container {
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
}

/* Styling for the text side */
.about-column-container h2 {
  color: #333;
  font-size: 28px;
}

.about-column-container p {
  font-size: 20px;
  color: #555;
  line-height: 1.6;
}

/* Form styles */
.about-column-container form {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Label styling */
.about-column-container form label {
  display: block;
  margin-bottom: 15px;
  font-size: 16px;
  color: #333;
}

/* Input and textarea styles */
.about-column-container form input[type="text"],
.about-column-container form input[type="email"],
.about-column-container form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  font-family: inherit;
  box-sizing: border-box;
}

.about-column-container form textarea {
  height: 150px;
  resize: vertical;
}

/* Submit button */
.about-column-container form button[type="submit"] {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.about-column-container form button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    align-items: center;
  }
  .about-column-container {
    width: 100%;
  }
}

  .content {
    text-align: justify;
  }

  .content-wide {
    text-align: center;
    padding: 40px;
  }

  .content-wide p{
    font-size: 20px;
  }

  .content p {
    font-size: 20px;
  }
  
  .image-section {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-right: 30px
  }

  .image-section-wide {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 20px;
  }
  
  .about-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  .about-image-wide {
    width: 30%;
    height: auto;
    border-radius: 8px;
  }
  