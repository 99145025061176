
/* Styles for single cards and card container */

/* NB. for jobs board styles see home.css */

/* Individual Cards */
 
.cards-container {
  display: grid; 
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  padding: 1rem;
}

/* Individual card styles */
.card {
  border: 2px solid #6b7280; /* border-gray-500 */
  border-radius: 0.5rem; /* rounded-lg */
  padding: 1rem; /* px-4 py-2 */
  margin: 1rem; /* m-4 */
  position: relative; /* relative */
  transition: box-shadow 0.2s ease-in-out;
  background-color: white;
  width: 300px
}

.card:hover {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1); /* hover:shadow-xl */
}

/* Position label styles */
.card .position-label {
  position: absolute; /* absolute */
  top: 0.25rem; /* top-1 */
  right: 0.5rem; /* right-2 */
  padding: 0.25rem 1rem; /* px-4 py-1 */
  background-color: #fca5a5; /* bg-red-300 */
  border-radius: 0.5rem; /* rounded-lg */
}

/* ID label styles */
.card-text {
  margin: 0.5rem 0; /* my-2 */
  color: black;
  font-size: 1rem;
  letter-spacing: var(--spacing);
}

/* Flex container styles */
.flex-container {
  display: flex; /* flex */
  align-items: center; /* items-center */
  gap: 0.5rem; /* gap-x-2 */
}

/* Icon styles */
.icon-red {
  color: #fca5a5; /* text-red-300 */
  font-size: 1.5rem; /* text-2xl */
}

.icon-large {
  font-size: 2rem; /* text-3xl */
}

.icon-blue {
  color: #1e40af; /* text-blue-800 */
}

.icon-green {
  color: #10b981; /* text-green-800 */
}

.icon-yellow {
  color: #f59e0b; /* text-yellow-600 */
}

.icon-red-dark {
  color: #ef4444; /* text-red-600 */
}

.icon-hover:hover {
  color: #000000; /* hover:text-black */
}

.icon-pointer {
  cursor: pointer; /* cursor-pointer */
}

/* Gap and padding adjustments */
.gap-bottom {
  margin-top: 1rem; /* mt-4 */
  padding: 1rem; /* p-4 */
}

/* CSS for the SearchBar component */

.search-bar {
    /* text-align: center; */
    padding: 180px 0; /* Add vertical padding for centering */
  }
  
  .search-box {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8); /* Add background for better readability */
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .search-box input {
    border: none;
    outline: none;
    padding: 10px;
    border-radius: 5px 0 0 5px;
    flex: 1;
  }
  
  .search-box-button {
    border: none;
    outline: none;
    padding: 10px;
    border-radius: 0 5px 5px 0;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .search-box-button:hover {
    background-color: #0056b3;
  }
  
  input[type="text"] {
    flex: 1;
    border: none;
    outline: none;
    padding: 8px;
    font-size: 16px;
  }
  
  .search-box-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 0 5px 5px 0;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .search-box-button:hover {
    background-color: #0056b3;
  }
  
  /* Back arrow */

  .back-arrow {
    font-size: 40px;
  }

  /* Modal Styles */

  /* InfoModal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  border-radius: 10px;
  padding: 2rem;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #888;
  transition: color 0.3s;
}

.close-button:hover {
  color: #333;
}

.modal-text {
  margin-top: 1rem;
  font-size: 1rem;
  color: #333;
  text-align: center;
}
