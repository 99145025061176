.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    max-width: 400px;
    width: 100%;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .modal-text {
    margin-bottom: 20px;
    font-size:20px
  }
  
  .radio-options {
    margin: 20px 0;
  }
  
  .radio-label {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 20px;
  }
  .radio-label input{
    margin-right:10px
  }
  
  .radio-text {
    margin-left: 10px; 
    font-size: 20px;
  }
  
  .purchase-button {
    background-color: #007BFF; /* Blue shade */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 20px;
    width: 100%;
  }
  
  .purchase-button:hover {
    background-color: #0056b3; /* Darker blue shade for hover */
  }
  